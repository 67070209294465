import request from '@/utils/request'

export function getGood(id) {
    return request({
        url: `/good/${id}`,
        method: 'get'
    })
}

export function updateGood(id, data) {
    if (id) {
        return request({
            url: `/good/${id}`,
            method: 'put',
            data: data
        })
    }

    return request({
        url: `/good`,
        method: 'post',
        data: data
    })
}