<template>
  <div>
    <el-dialog
      title="编辑展品信息"
      :visible.sync="visible"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="150px"
        v-loading="loading"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="序号" prop="sortNum">
              <el-input
                v-model="form.sortNum"
                placeholder="请输入序号"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="展品名称" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入展品名称"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="展品介绍" prop="introduction">
              <el-input
                type="textarea"
                :rows="3"
                resize="none"
                v-model="form.introduction"
                placeholder="展品介绍"
                maxlength="200"
                show-word-limit
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="品牌" prop="brand">
              <el-input
                v-model="form.brand"
                placeholder="请输入品牌"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="规格" prop="norms">
              <el-input
                v-model="form.norms"
                placeholder="请输入规格"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="价格" prop="price">
              <el-input
                v-model="form.price"
                placeholder="请输入价格"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="产地/服务地区" prop="place">
              <el-input
                v-model="form.place"
                placeholder="请输入产地/服务地区"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getGood, updateGood } from "@/api/goods/good.js";

export default {
  name: "Edit",
  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      // 表单校验
      rules: {},
    };
  },
  methods: {
    get(id) {
      this.visible = true;
      this.loading = true;
      getGood(id).then((res) => {
        this.form = res;
        this.loading = false;
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          updateGood(this.form.id, this.form)
            .then(() => {
              this.msgSuccess();
              this.loading = false;
              this.visible = false;

              this.$parent.search();
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    show(id) {
      this.get(id);
    },
  },
};
</script>

<style></style>
